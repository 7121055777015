var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-sm-12" },
      _vm._l(_vm.caches, function (item, index) {
        return _vm.caches
          ? _c("div", { key: index, staticClass: "card" }, [
              _c("div", { staticClass: "card-body form-card" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12 col-lg-2 col-md-2" }, [
                    _c("b", [_vm._v(_vm._s(index))]),
                  ]),
                  _c("div", { staticClass: "col-sm-12 col-lg-10 col-md-10" }, [
                    _c("table", { staticClass: "table table-bordered" }, [
                      _vm._m(0, true),
                      _c(
                        "tbody",
                        _vm._l(item, function (sub, key) {
                          return _c("tr", [
                            _c("td", [_vm._v(_vm._s(sub))]),
                            _c(
                              "td",
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "danger",
                                    icon: "el-icon-delete",
                                    circle: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clearCache(key)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e()
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("td", [_vm._v("Tiêu đề")]),
        _c("td", [_vm._v("Xóa cache")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }