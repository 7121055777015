<template lang="html">
  <div class="row">
    <div class="col-sm-12">

      <div v-if="caches" v-for="(item, index) in caches" :key="index" class="card">
        <div class="card-body form-card">
          <div class="row">
            <div class="col-sm-12 col-lg-2 col-md-2">
              <b>{{ index }}</b>
            </div>
            <div class="col-sm-12 col-lg-10 col-md-10">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <td>Tiêu đề</td>
                    <td>Xóa cache</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(sub, key) in item">
                    <td>{{ sub }}</td>
                    <td>
                      <el-button type="danger" icon="el-icon-delete" circle @click="clearCache(key)"></el-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { Input, Button, Switch, Message } from 'element-ui';
import { mapState } from 'vuex'

export default {
  components: {
    ElInput: Input,
    ElButton: Button,
    ElSwitch: Switch,
    Message
  },

  computed: {
    caches() {
      var data = this.$store.state.allCaching || [];
      return data;
    }
  },

  mounted() {
    this.$store.dispatch('setPageTitle', 'Caching');
    this.$store.dispatch('setCurrentActions', []);
    this.$store.dispatch('fetchAllCaching');
  },

  methods: {

    clearCache(key) {
      var data = {
        key: key
      };
      this.$store.dispatch('clearCache', data).then(res => {
        if (res.success) {
          Message({
            message: 'Đã xóa',
            type: 'success'
          });
        } else {
          if (res.errors) {
            for (let err in res.errors) {
              Message({
                message: res.errors[err][0],
                type: 'error'
              });
            }
          } else {
            Message({
              message: res.message,
              type: 'error'
            });
          }
        }
      });
    }
  },

  watch: {

  },
  destroyed(){
    this.$store.dispatch('setCurrentActions', []);
    this.form = {};
    this.$store.state.allSettings = [];
  }
}
</script>
